<template>
    <contentCard :loading="loading">
        <template slot="cardContent">
			<div>
                <v-row justify="center" >
                    <v-col class="text-center col-md-6 col-xl-5">
                        <div class="font-weight-bold body-1">
                            Editar garantía
                        </div>
                    </v-col>
                </v-row>
                <v-row justify="center">
                    <v-col cols="12" md="6" xl="5">
                        <v-form ref="form" @submit.prevent="save">
                            <cont-warr-form ref="ContractorWarrForm" :values="form" @save="save" :serverErrors="serverErrors" :edit="true"></cont-warr-form>
                        </v-form>
                    </v-col>
                    
                </v-row>
            </div>

            <v-row justify="center" align="end">
				<v-col cols="12" md="6" xl="5" class="">
                    <v-row justify="end" class="px-3 py-3">
                        <secondary-button :props="cancelBtnData" class="mr-0 mr-sm-2 mb-3 mb-sm-0"></secondary-button>
                        <primary-button :props="saveBtnData" class=""></primary-button>  
                    </v-row>
				</v-col>
			</v-row>
        </template>
    </contentCard>
</template>

<script>

import ContractorWarrForm from "./Form.vue";
import Utils from '../../../helpers/Utils';
import Object2Form from '../../../helpers/Object2Form';
export default {
    components: {
        'cont-warr-form': ContractorWarrForm,
    },
	data(){
		return {
            construction: this.$route.params.construction,
            loading: false,
            serverErrors: {},
            slug: this.$route.params.slug,
            form: {
                customer_id:"",
                receivable_account_id: "",
                start_ejecution: "",
                end_ejecution: "",
                percentage: "0",
                amount: "",
                comments: "",
                quotation_file_url: "",
                quotation_file: [],

                paAmount: '0,0000'
            },
            cancelBtnData: {
                text: "Cancelar",
                icon: "mdi-close",
                to: "IncomeConstructionWarranties",
                block:false,	
                click: () => {}
            },
            saveBtnData: {
                text: "Guardar cambios",
                icon: "mdi-content-save-outline",	
                click: this.save,
                block:false,
                loading: false,
            },
            snackbarProps: {
                visible: false,
				color: 'success',
				timeout: 3000,
				text: '',
            },
		}
    },
    mounted(){
        window.addEventListener('resize', this.onResize, { passive: true });
        this.onResize();
        this.index();
    },
	methods: {
        onResize () {
            
            if(window.innerWidth < 600){
                this.saveBtnData.block = true;
                this.cancelBtnData.block = true;
            }
            else{
                this.saveBtnData.block = false;
                this.cancelBtnData.block = false;
            }
        },
		validate () {
            if(!Utils.objectIsEmpty(this.serverErrors))
                return false;

			return this.$refs.form.validate();
            // return true;
		},
        index()
        {
            this.loading = true;
            this.$store.state.loading = true;
            this.$api.constructionWarranty.get(this.$store.state.business, this.construction, this.slug)
                .then((resp) => {
                    this.form.start_ejecution = resp.data.start_ejecution;
                    this.form.end_ejecution = resp.data.end_ejecution;
                    this.form.percentage = resp.data.percentage;
                    this.form.amount = resp.data.amount;
                    this.form.comments = resp.data.comments;
                    this.form.customer_id = resp.data.construction_receivable_account.customer.slug;
                    this.form.receivable_account_id = resp.data.construction_receivable_account.slug;
                    this.form.paAmount = parseFloat(resp.data.construction_receivable_account.amount).toFixed(4);
                    this.form.quotation_file = [];
                    this.form.quotation_file_url = resp.data.quotation_file_url;
                    console.log(this.form.contractor_payable_account_id)

                })
                .catch((error) => {
                    console.log(error);
                })
                .finally(() => {
                    this.loading = false;
                    this.$store.state.loading = false;
                })
            ;
        },
        save(){
            
            console.log(this.form);
            
            if(this.validate()){
                
                let form = this.prepareRequest(this.form);
                console.log(form);
                this.saveBtnData.loading = true;
                this.$store.state.loading = true;
                this.$api.constructionWarranty.update(this.$store.state.business, this.construction, this.slug,form)
                    .then(() => {
                        //console.log(response)

                        this.$store.dispatch('snackbarSuccess', `Se ha creado correctamente`);
                        this.$router.push({name: 'IncomeConstructionWarranties'});
                    })
                    .catch((error) => {
                        var errors = []
                        switch(error.response.status){
                            case 422: 
                                
                                if(("error" in error.response.data)){
                                    if(error.response.data.error instanceof Object){
                                        Object.keys(error.response.data.error).forEach((x) => {
                                            
                                               
                                            console.log(x);
                                            this.serverErrors = error.response.data.error;
                                            error.response.data.error[x].forEach((y) => {
                                                this.serverErrors[x] = Utils.deleteWord(x,y);
                                                errors.push(Utils.deleteWord(x,y))
                                            })
                                        });
                                    }else{
                                        errors.push(error.response.data.error)
                                    }
                                    
                                    this.$store.dispatch('snackbarError', errors.join('.<br>'));
                                }
                               
                            break;
                           
                        }
                        console.log(this.serverErrors)
                       
                    })
                    .finally(() => {
                        this.saveBtnData.loading = false;
                        this.$store.state.loading = false;
                    })
                ;
            }else{
                
                this.$store.dispatch('snackbarError', `Formulario inválido`);
            }
            // eslint-disable-next-line no-console
        },
        prepareRequest(request)
        {
            let data = JSON.parse(JSON.stringify(request));
            
            data.quotation_file = request.quotation_file;

            if(request.quotation_file_url == '' && (request.quotation_file instanceof Array))
                data.quotation_file = null;

            if(request.quotation_file_url != '' && (request.quotation_file instanceof Array))
                delete data.quotation_file;

            return Object2Form.objectToFormData(data,'',[]);
        },
    },
}
</script>

<style>

</style>